.grid-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
}

.grid-item {
  padding: 16px;
}

.dynamic-form {
//   display: flex;
//   gap: 20px;
//   width: 65%;

  .form-field {
    width: 100%;
  }
  .background {
    background-color: white;
    height: auto;
  }

  .submit-button {
    margin-top: 20px;
  }
  textarea .custom-textarea {
    height: 200px;
  }

  textarea.form-field {
    width: 100%;
    font-family: Lexend;
    height: 150px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }
  .Rectangle-5624 {
    width: 250px;
    height: 200px;
    background-color: #EBEDEB;
    border-radius: 8px;
    box-shadow: 0px 4px 14px 0px rgba(129, 139, 125, 0.04);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      width: auto;
    }
  }
  .Rectangle-5625 {
    width: 250px;
    height: 200px;
    background-color: #EBEDEB;
    border-radius: 8px;
    box-shadow: 0px 4px 14px 0px rgba(129, 139, 125, 0.04);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Rectangle-5626 {
    width: 200px;
    height: 200px;
    border-radius: 8px;
    text-align: center;
    @media (max-width: 768px) {
      width: auto;
      height: 180px;
      display: grid;
      justify-content: center;
    }
  }
  .Rectangle-5627 {
    width: 200px;
    height: 200px;
    border-radius: 8px;
    text-align: center;
  }
  .Rectangle-5621 {
    width: 1000px;
    height: 60px;
    margin: 12px 0 30px;
    border-radius: 6px;
    border: solid 1px #d0d0d0;
    background-color: #fff;
  }
  .Rectangle-5772 {
    width: 896px;
    height: 48px;
    margin: 0 24px 0 0;
    text-align: center;
    padding-top: 1%;
    border-radius: 6px;
    border: solid 1px #d0d0d0;
    background-color: #fff;
  }
  .Rectangle-5773 {
    width: 80px;
    height: 48px;
    padding: 10px 26px 23.4px 26px;
    border-radius: 6px;
    border: solid 1px #d0d0d0;
    background-color: #fff;
  }
  .trash {
    height: 23px;
  }
  .filename {
    display: flex;
  }
  .file-upload {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: 2px dashed #ccc;
    padding: 16px;
    text-align: center;
    cursor: pointer;

    &.drag-over {
      border-color: #007bff;
      background-color: #f0faff;
    }

    .file-label {
      font-weight: bold;
    }

    .file-input {
      display: none;
    }
  }
  .file-preview {
    display: flex;
    justify-content: center;
    align-items: center;

    img,
    video {
      max-width: 100%;
      max-height: 200px;
    }
  }

  .custom-file-input {
    position: relative;
  }

  .custom-file-label {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    margin: 0;
  }

  input[type='file'] {
    display: none;
  }

  .custom-file-label:hover {
    background-color: #0056b3;
  }

  .Drag-and-drop-or-Choose-file-to-upload {
    // height: 30px;
    // margin: 30px 0 0;
    font-family: Lexend;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #666;
  }
  .Drag-and-drop-or-Choose-file-to-upload .text-style-1 {
    font-weight: normal;
    color: #00b894;
  }
  
  .label-style {
    font-size: 15px;
  }
}
