.PhoneInputInput {
  border: none;
  &:active {
    border: none;
  }
  &:focus-visible {
    border: none;
  }
}
#otp {
  text-align: center;
}
