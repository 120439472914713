@import url("https://fonts.googleapis.com/css2?family=Lexend&family=Neuton&display=swap");

@font-face {
  font-family: "Kufam";
  src: url("../../public/fonts/Kufam/Kufam-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Lexend", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: #E3F2FD;
  background-color: #ffffff;
}

main {
  // background-color: #E3F2FD !important;
  background-color: white !important;
}

:root {
  // soft blue
  // --primary: #4682B4;
  --primary: #0792c0;
  // --secondary: #0D47A1;
  --secondary: #00a4e2;
  --hover: #36628b;
  // --bg: #E3F2FD;
  --secondary-bg: #cbcbcb;
  --bg: #ffffff;
  --btn-text: #ffffff;
  // What do our customers say
  // --light-blue: linear-gradient(135deg, #f0f4ff, #d0eaff);
  // --light-blue: #d6e0eb;

  // What do our customers say
  --background: #f4faff; // lite blue -f4faff , eef7ff // e8ebef

  --border-color: #36628b;
  --star-color: #ffd700;
}

.kufam-font {
  font-family: "Kufam", sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .Toastify__toast-body {
    background-color: #ffffff;
  }
  .Toastify__toast-container {
    width: 100% !important;
    left: 50%;
    transform: translateX(-50%) !important;
    padding: 0;
    top: 0.5em !important;
    left: 50% !important;
    text-align: -webkit-center;
  }

  .Toastify__toast {
    font-size: 13px !important;
    padding: 10px !important;
    text-align: left;
    margin-bottom: 8px !important;
  }
  .Toastify__toast {
    width: 70%;
    border-radius: 10px !important;
  }
}
