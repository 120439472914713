.grid-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
}

.grid-item {
  padding: 16px;
}

.dynamic-form {
//   display: flex;
//   gap: 20px;
//   width: 65%;

  .form-field {
    width: 100%;
  }
  .background {
    background-color: white;
    height: auto;
  }

  .submit-button {
    margin-top: 20px;
  }
  textarea .custom-textarea {
    height: 200px;
  }

  textarea.form-field {
    width: 100%;
    font-family: Lexend;
    // height: 150px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }
  .Rectangle-90 {
    width: 300px;
    height: 300px;
    background-color: #EBEDEB;
    box-shadow: 0px 4px 14px 0px rgba(129, 139, 125, 0.04);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}
  .filename {
    display: flex;
  }
  .file-upload {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: 2px dashed #ccc;
    padding: 16px;
    text-align: center;
    cursor: pointer;

    &.drag-over {
      border-color: #007bff;
      background-color: #f0faff;
    }

    .file-label {
      font-weight: bold;
    }

    .file-input {
      display: none;
    }
  }
  .file-preview {
    display: flex;
    justify-content: center;
    align-items: center;

    img,
    video {
      max-width: 100%;
      max-height: 200px;
    }
  }

  .custom-file-input {
    position: relative;
  }

  .custom-file-label {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    margin: 0;
  }

  input[type='file'] {
    display: none;
  }

  .custom-file-label:hover {
    background-color: #0056b3;
  }

  .Drag-and-drop-or-Choose-file-to-upload {
    // height: 30px;
    // margin: 30px 0 0;
    font-family: Lexend;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #666;
  }
  .Drag-and-drop-or-Choose-file-to-upload .text-style-1 {
    font-weight: normal;
    color: #00b894;
  }
  
  .label-style {
    font-size: 15px;
  }
}

.image-gallery-icon {
  color: #A7A7A7 !important;
  filter: none !important;
  -webkit-filter: none !important;
}

.image-gallery-icon:hover {
  color: #000 !important;
  filter: none !important;
  -webkit-filter: none !important;
}

.image-gallery-left-nav:hover, .image-gallery-right-nav:hover{
  border: 2px solid #000 !important;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  width: 20px !important;
  height: 20px !important;
}

.image-gallery-left-nav, .image-gallery-right-nav {
  padding: 10px !important;
  border: 2px solid #A7A7A7 !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
  -webkit-border-radius:0 !important;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
  border: none !important
}

.image-gallery-thumbnail {
  width: 80px !important;
  height: 80px !important;
 
}
.image-gallery-thumbnail:hover {
    border: none !important;
    width: 80px !important;
}


.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
  border: none !important;
  height: 80px !important;

  img {
    border: 4px solid #568A60 !important;
  }
}

.image-gallery-thumbnails {
  margin-top: 20px;
}

